import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';

const termsPage: React.FC<{}> = () => (
	<PrimaryLayout>
		<SEO title="terms and conditions" />
		<Container py="40px">
			<h1> Our Terms and Conditions </h1>

			<p> We and our affiliates provide their services to you subject to the following conditions. If you visit, sell or shop with us online, you accept these conditions.</p>

			<h2> Privacy </h2>
			<p> Please review our Privacy Notice, which also governs your visit to this site, to understand our practices. </p>

			<h2> Policies </h2>
			<p> As a customer, please be aware that each seller you are purchasing merchandise from have their own shipping & return policies. We encourage you to please read each sellers return policies before placing your order.</p>

			<h2> Electronic Communications </h2>
			<p> When you visit this site or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. </p>

			<h2> Copyright </h2>
			<p> All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Krish Enterprises LLC. or its content suppliers and protected by United States and international copyright laws. All software used on this site is the property of Krish Enterprises LLC. or its software suppliers and protected by United States and international copyright laws. </p>

			<h1> Disclaimer </h1>

			<p> The merchandise sold at IndusFood.us is posted, sold and shipped directly by Krish Enterprises LLC. Please contact us if there are any questions. Prices are subject to change without notice. We do not offer price adjustments or apply special promotions on prior sale. </p>

			<p> All disputes are subject to the law and jurisdiction of Illinois, United States of America. </p>
		</Container>		
	</PrimaryLayout>
);

export default termsPage;